import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Greyish } from 'libs/css';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';
import React, { useRef } from 'react';

export default function ToolTipCito ({ text, id, size, icon = faQuestionCircle, color = Greyish, children, childrenWrapperStyles = {}, disabled = false }) {
    const ref = useRef();
    if (disabled) return children;

    return <>
        {children ? (
            <div ref={ref} style={{ padding: 0, width: 'fit-content', ...childrenWrapperStyles }}>
                {children}
            </div>
        ): (
            <FontAwesomeIcon id={id} ref={ref} color={color} icon={icon} transform="shrink-2" className="ms-2" size={size}/>
        )}
        <UncontrolledTooltip target={ref}>{text}</UncontrolledTooltip>
    </>;
}
