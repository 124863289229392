import React, { useState } from 'react';
import { TailSpin } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faTrash, faUpload, faCancel } from "@fortawesome/free-solid-svg-icons";
import { DarkSlateBlue, Redish } from "libs/css";
import ButtonS3Uploader from "./ButtonS3Uploader";
import { STATIC_CDN_PATH } from "../constants";


const AssetUploader = ({ file, setFile, required, path, accept, isUploading, allowReupload = false }) => {
    const [uploading, setUploading] = useState(false);
    const [reUpload, setReUpload] = useState(false)

    return <>
        {uploading && <TailSpin color='#b2bec3' width={25} height={25} />}
        {file && !reUpload && (
            <>
                {allowReupload && 
                    <FontAwesomeIcon icon={faUpload} fixedWidth color={DarkSlateBlue} onClick={() => setReUpload(true)} />
                }
                <a href={file} download target='_blank' rel='noopener noreferrer'>
                    <FontAwesomeIcon icon={faFileDownload} fixedWidth color={DarkSlateBlue} />
                </a>
                <FontAwesomeIcon icon={faTrash} fixedWidth color={Redish} onClick={() => setFile(null)} />
            </>
        )}
        {(!file || reUpload) && (
            <>
                <ButtonS3Uploader
                    className='button-up'
                    loading={false}
                    onFinish={(result) => {
                        isUploading && isUploading([path, false]);
                        setUploading(false);
                        setFile(result.fileUrl);
                        setReUpload(false);
                    }}
                    onStart={() => {
                        isUploading && isUploading([path, true]);
                        setUploading(true);
                    }}
                    s3UrlImage={STATIC_CDN_PATH}
                    accept={accept}
                    isRequired={required}
                    path={path}
                />
                {reUpload && <FontAwesomeIcon icon={faCancel} fixedWidth color={Redish} onClick={() => setReUpload(false)} />}
            </>
        )}
    </>;
};
export default AssetUploader
