import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { APOLLO_EXTENSION, AUTH_SCOPES, GRAPHQL_ENDPOINT, OKTA_CLIENT_ID, OKTA_ISSUER_URL, OKTA_URL_BASE } from '../constants';

import { setContext } from '@apollo/client/link/context';
import UPshowLogo from '../Assets/UPshow_logo_primary.png';

import '../Styles/App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Home';
import { UPShowRedirectSecurity, getToken } from '@upshow/auth';
import packageInfo from '../../package.json';

const { version } = packageInfo;

const link = new BatchHttpLink({
    uri: GRAPHQL_ENDPOINT,
    batchMax: 5, // No more than 5 operations per batch
    batchInterval: 20, // Wait no more than 20ms after first batched operation,
    batchKey: operation => operation.operationName && operation.operationName.startsWith('eager_') ? 'eager-' + operation.operationName : 'normal'
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            'X-App-Version': version
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache({
        typePolicies: {
            Backend: {
                fields: {
                    TrackingCodes: relayStylePagination(['query']),
                    DirectShareContentEdge: relayStylePagination(['filters']),
                    Users: relayStylePagination(['filters']),
                    Devices: relayStylePagination(['DeviceFiltering']),
                    StreamEnabledDevices: relayStylePagination()
                }
            },
            Device: {
                fields: {
                    Timeline: relayStylePagination(['actions', 'device_id', 'from', 'to'])
                }
            },
            User: {
                fields: {
                    Notifications: relayStylePagination(['dismissed'])
                }
            }
        },
        possibleTypes: {
            EntertainmentFeed: [
                'UpshowNowChannel',
                'Application',
                'PlutoTvChannel',
                'LiveEvent',
                'MediaChannel',
                'UpshowGame'
            ]
        }
    }),
    connectToDevTools: APOLLO_EXTENSION
});

export default function App () {
    
    return <UPShowRedirectSecurity
            oktaBaseURL={OKTA_URL_BASE}
            issuer={OKTA_ISSUER_URL}
            clientId={OKTA_CLIENT_ID}
            baseURL={window.location.origin}
            scopes={AUTH_SCOPES}
            login={{
                className: 'd-flex flex-column align-items-center justify-content-center h-100',
                logo: UPshowLogo
            }}
            startingPath={window.location.pathname}
    >
        <ApolloProvider client={client}>
            <Home/>
        </ApolloProvider>
    </UPShowRedirectSecurity>;
}

